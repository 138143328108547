import React, {useCallback, useEffect, useState} from 'react'
import {Input, Select, Table, message, Row, Col, Tag} from 'antd'
import b_ from 'b_'
import TableSettings from "../../Components/TableSettings"
import useStorageState from '../../Utils/useStorageState'
import PrintUserStatus from "../../Components/Prints/PrintUserStatus";
import EditableField from "../../Components/EditableField";
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {updateUsers} from "../../Reducers/users";
import {JSONTree} from 'react-json-tree';
import theme from '../../Utils/jsonTheme';
import SetMult from "./SetMult";
import SetAlert from "./SetAlert";
import SetPassword from "./SetPassword";
import {Link} from "react-router-dom";
import EditReferrer from './EditReferrer'
import Expandable from './Expandable'
import VerifyUserEmail from './VerifyUser'
import PrintDate from '../../Components/Prints/PrintDate'
// import CodeFilters from "../Codes/CodeFilters";

const b = b_.lock('UsersTable')

const salt = process.env.REACT_APP_REF_SALT

function UsersTable({
                      list,
                      tableOnChange,
                      pagination,
                      isLoading,
                      afterUpdate,
                      onFilter,
                      filters,
                      sorter,
                      selected,
                      onSelectChange,
                      platforms,
                      statuses,
                      walletCurrency,
                    }) {

  const dispatch = useDispatch();

  const loadings = useSelector(state => ({
    update: state.users.updateUsers.isLoading,
  }), shallowEqual);

  const onUpdateUsers = useCallback((data) => {
    dispatch(updateUsers(data)).then(() => {
      message.success('User(s) has been updated!');
      afterUpdate();
    }).catch(error => {
      console.log(error);
      message.error('User(s) update failed');
    });
  }, [dispatch, afterUpdate]);

  const GetColumns = useCallback(() => {

    return [
      {
        title: 'ID',
        dataIndex: 'id',
        key: 'id',
        width: 40,
        align: 'center',
        fixed: 'left',
        sorter,
        sortOrder: sorter.field === 'id' ? sorter.order : null,
        render: (id, {referral}) => <span>{id} <small style={{opacity: '0.5'}}>{referral}</small></span>
      },
      {
        title: "Referrer",
        dataIndex: "referrer",
        width: 70,
        align: 'center',
        fixed: 'left',
        sorter,
        sortOrder: sorter.field === 'referrer' ? sorter.order : null,
        render: (value, rec) => (
            <EditableField
                handleSave={(referrer) => onUpdateUsers({ids: [rec.id], referrer: referrer ? referrer : ""})}
                title="Update Referrer"
                titlePopover="Edit Referrer"
                isLoading={loadings.update || isLoading}
                iconClassName={b('edit')}
                withTitle
                initialValue={value && value - salt}
                changeBlock={({onChange, ...props}) => (
                    <Input {...props}
                           size="small"
                           type="number"
                           allowClear
                           onChange={e => onChange(e.target.value)}/>
                )}>
              {value ? value - salt : ''}
            </EditableField>
        )
      },
      {
        title: 'Login',
        dataIndex: 'email',
        key: 'email',
        fixed: 'left',
        width: 250,
        sorter,
        sortOrder: sorter.field === 'email' ? sorter.order : null,
        render: (id, {email}) => <Link to={`/?limit=50&offset=0&filters[userEmail][]=${email}`}>{email}</Link>
      },
      {
        title: "Verified",
        dataIndex: "verified",
        key: 'verified',
        align: 'center',
        width: 80,
        render: (verified) => <div><Tag color={verified === "true" ? "green" : verified === "false" ? "red" : "default"}>{verified === "null" ? "unknown" : verified}</Tag></div>,
      },
      {
        title: "Created",
        dataIndex: "creationDate",
        key: 'creationDate',
        width: 250,
        render: (create) => <PrintDate withTime date={create}/>,
      },
      {
        title: "Last login",
        dataIndex: "sessionTs",
        key: 'sessionTs',
        width: 250,
        render: (lastLogin) => <PrintDate withTime date={lastLogin}/>,
      },
      {
        title: "Status",
        dataIndex: "status",
        align: "center",
        width: 150,
        filters: [
          {text: 'Enabled', value: 'Enabled'},
          {text: 'Disabled', value: 'Disabled'},
        ],
        filterMultiple: false,
        filteredValue: filters['status'] || null,
        render: (status, rec) => (
          <>
            <PrintUserStatus status={status} field={"status"} rec={rec}
                             handleSave={(status) => onUpdateUsers({ids: [rec.id], status})}/>

            <EditableField handleSave={(status) => onUpdateUsers({ids: [rec.id], status})}
                           title="Update Status"
                           withTitle
                           titlePopover="Edit Status"
                           isLoading={loadings.update || isLoading}
                           iconClassName={b('edit')}
                           initialValue={status}
                           changeBlock={(props) => (
                             <Select {...props} size="small">
                               <Select.Option value="Disabled">Disabled</Select.Option>
                               <Select.Option value="Enabled">Enabled</Select.Option>
                             </Select>
                           )}>
            </EditableField>
          </>
        ),
      },
      {
        title: 'Mult',
        dataIndex: 'mult',
        key: 'mult',
        width: 200,
        sorter,
        sortOrder: sorter.field === 'mult' ? sorter.order : null,
        render: (json, rec) => (
          <>
            <Row>
              <Col>
                {json && <JSONTree hideRoot theme={theme} data={json.byPlatform}/>}
              </Col>
              <Col>
                <SetMult afterUpdate={afterUpdate} selected={[rec.id]} iconClassName={b('edit')} isCell={true}/>
              </Col>
            </Row>
          </>
        )
      },
      {
        title: "Payout Mult",
        dataIndex: "payoutMult",
        width: 100,
        align: "center",
        sorter,
        sortOrder: sorter.field === 'payoutMult' ? sorter.order : null,
        render: (value, rec) => (
          <EditableField
            handleSave={(payoutMult) => onUpdateUsers({ids: [rec.id], payoutMult: payoutMult ? payoutMult / 100 : ""})}
            title="Update Payout Mult"
            titlePopover="Edit Payout Mult"
            isLoading={loadings.update || isLoading}
            iconClassName={b('edit')}
            withTitle
            initialValue={value}
            changeBlock={({onChange, ...props}) => (
              <Input {...props}
                     addonAfter="%"
                     size="small"
                     type="number"
                     allowClear
                     onChange={e => onChange(e.target.value)}/>
            )}>
            {value + (value && " %")}
          </EditableField>
        )
      },
      {
        title: 'Paid out, USD',
        dataIndex: 'paidOutAmountBtc',
        key: 'paidOutAmountBtc',
        align: "right",
        width: 120,
        sorter,
        sortOrder: sorter.field === 'paidOutAmountBtc' ? sorter.order : null,
        render: (value, {email}) => <Link to={`/payouts?limit=50&offset=0&filters[userEmail][]=${email}`}>{value}</Link>
      },
      {
        title: 'Balance, ' + walletCurrency,
        dataIndex: "balanceUsd",
        align: "center",
        key: 'balanceUsd',
        sorter,
        sortOrder: sorter.field === 'balanceUsd' ? sorter.order : null,
        width: 170,
        render: (bal, {email}) => <Link to={`/transactions?limit=50&offset=0&filters[userEmail][]=${email}`}>{bal}</Link>
      },
      {
        title: 'Alert',
        dataIndex: 'alert',
        key: 'alert',
        align: 'center',
        width: 90,
        render: (value, rec) =>
          <SetAlert afterUpdate={afterUpdate} selected={[rec.id]} iconClassName={b('edit')} isCell={true} alert={rec}/>
      },
      {
        title: "BTC Wallet",
        dataIndex: "btcWallet",
        width: 120,
        render: (value, rec) => (

          <div>
            {value &&
              <a href={`https://blockchain.info/address/${value}`} rel="noreferrer noopener"
                 target="_blank">{value.substr(0, 10) + '...'}</a>
            }
            <EditableField handleSave={(btcWallet) => onUpdateUsers({ids: [rec.id], btcWallet})}
                           title="Update BTC Wallet"
                           titlePopover="Edit BTC Wallet"
                           isLoading={loadings.update || isLoading}
                           iconClassName={b('edit')}
                           withTitle
                           initialValue={value}
                           changeBlock={({onChange, ...props}) => (
                             <Input {...props} size="small" onChange={e => onChange(e.target.value)} allowClear/>
                           )}/>
          </div>
        )
      },
      {
        title: "Comment",
        dataIndex: "comment",
        width: 170,
        sorter,
        sortOrder: sorter.field === 'comment' ? sorter.order : null,
        render: (value, rec) => (
          <EditableField handleSave={(comment) => onUpdateUsers({ids: [rec.id], comment})}
                         title="Comment"
                         titlePopover="Comment"
                         isLoading={loadings.update || isLoading}
                         iconClassName={b('edit')}
                         withTitle
                         initialValue={value}
                         changeBlock={({onChange, ...props}) => (
                           <Input {...props} size="small" onChange={e => onChange(e.target.value)} allowClear/>
                         )}>
            {value && value.substr(0, 15) + (value.length > 15 ? '...' : '')}
          </EditableField>
        )
      },
      {
        title: 'Action',
        dataIndex: 'action',
        key: 'action',
        width: 150,
        render: (_, record) =>
          <div className="actionButtonsGroup">
            <SetPassword userId={record.id} afterUpdate={afterUpdate} isPayout={false}/>
            <SetPassword userId={record.id} afterUpdate={afterUpdate} isPayout={true}/>
            <VerifyUserEmail userId={record.id} afterUpdate={afterUpdate} />
            {/* <EditReferrer userId={record.id} afterUpdate={afterUpdate} isPayout={true}/> */}
          </div>
      },
    ];
  }, [filters, list])

  const [columns, setColumns] = useState(GetColumns());

  const [Checkboxs, setCheckbox] = useState([]);

  const [AllCheckbox, setAllCheckbox] = useStorageState('codes_table', {
    checkedList: Checkboxs,
    indeterminate: false,
    checkAll: true,
  });

  const onChangeCheckList = useCallback((checkedList) => {
    setAllCheckbox({
      checkedList,
      indeterminate: !!checkedList.length && checkedList.length < columns.length,
      checkAll: checkedList.length === columns.length,
    });
  }, [Checkboxs]);

  useEffect(() => {
    let allCheckList = []
    if (list.length && !Checkboxs.length) {
      GetColumns().forEach((e) => {
        allCheckList.push(e[Object.keys(e)[1]])
        setCheckbox(allCheckList)
      })
    }
  }, [list, AllCheckbox, Checkboxs.length, GetColumns])

  useEffect(() => {
    if (AllCheckbox.checkAll) {
      setAllCheckbox({
        checkedList: GetColumns().map(e => e.title),
        indeterminate: false,
        checkAll: true,
      })
    }
  }, [AllCheckbox.checkAll, GetColumns, setAllCheckbox])

  const onChangeCheckAll = useCallback((e) => {
    setAllCheckbox({
      checkedList: (e.target.checked && columns.map(e => e.title)) || [],
      indeterminate: false,
      checkAll: e.target.checked,
    });
  }, [Checkboxs]);

  useEffect(() => {
    setColumns(GetColumns())
  }, [list, isLoading, filters, GetColumns])

  const columnsFilter = columns.filter(e => AllCheckbox.checkedList.indexOf(e.title) !== -1);
  // const loader = Object.keys(loadings).some(e => !!loadings[e]) || loading
  return <div className={b()}>
    {/*<CodeFilters filters={filters} onFilter={onFilter} className="mb"/>*/}
    <Table
      className={b()}
      bordered
      dataSource={list}
      scroll={{x: "max-content"}}
      size="middle"
      onChange={(a, b, c) => tableOnChange(a, b, c)}
      onFilter={onFilter}
      pagination={pagination}
      loading={isLoading}
      columns={columnsFilter}
      rowClassName={() => b('lock')}
      rowKey="id"
      rowSelection={{
        selectedRowKeys: selected,
        onChange: onSelectChange,
      }}
      title={() => <TableSettings
        onChangeCheckAll={onChangeCheckAll}
        allChecks={AllCheckbox}
        Checkboxs={AllCheckbox.checkedList}
        TableColumn={() => GetColumns()}
        setCheckbox={onChangeCheckList}
      />}
      expandable={{
        // expandedRowRender: record => {record?.referees ? record?.referees?.map((refer) => <span key={refer.id}>{refer.email}</span>) : <span>No data</span>}
        expandedRowRender: record => <Expandable record={record} />
      }}
    />
  </div>
}

export default UsersTable;
