import React, {useCallback} from 'react';
import {Button, Drawer, Form, Input, message, Popconfirm, Select} from 'antd';
import {shallowEqual, useDispatch, useSelector} from 'react-redux';
import frc from 'front-end-common';
import {DeleteOutlined, LoadingOutlined, PlusOutlined} from "@ant-design/icons";
import {useEffect} from "react";
import {addEditCard, deleteEditCard} from "../../../Reducers/cards";

const useBoolean = frc.hooks.useBoolean;

function AddEditCard({className, afterUpdate, platforms, rec, add}) {
  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {isLoading, payload: addEditResult} = useSelector(state => state.cards.addEditResult, shallowEqual);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleSuccess = useCallback((data) => {
    let card = {
      id: add ? null : rec.id,
      platform: add ? data.platform : rec.platform,
      group: add ? data.group : rec.group,
      currency: add ? data.currency : rec.currency,
      name: data.name,
      basePrice: data.basePrice,
      locked: add ? false : rec.locked,
      title: data.title
    }
    dispatch(addEditCard(card))
    .then(() => {
      message.success(add ? 'Code has been added!' : 'Code has been edited');
      form.resetFields()
      close();
      afterUpdate()
    }).catch(error => {
      console.error(error);
      message.error(add ? 'Failed to add code.' : 'Failed to edit code.');
    });
  }, [dispatch, close]);

  useEffect(() => {
    if (!add) {
      form.setFields([
        {name: "platform", value: rec.platform},
        {name: "group", value: rec.group},
        {name: "currency", value: rec.currency},
        {name: "name", value: rec.name},
        {name: "basePrice", value: rec.basePrice},
        {name: "title", value: rec.title},
      ])
    }
  }, [rec])


  const onPlatformChange = (value) => {
    form.resetFields(['group'])
    form.resetFields(['currency'])
  }

  return <>
    {add ? (
      <Button className={className} onClick={open} icon={<PlusOutlined/>}
              type="primary"> Add </Button>) : (
      <Button className={className} type="link" size="small"
              onClick={open}> Edit </Button>
    )}
    < Drawer onClose={close}
             visible={isOpen}
             title={add ? "Add" : "Edit"}
             placement="right"
             width={600}>
      <Form layout="vertical" onFinish={handleSuccess} form={form}>
        <Form.Item
          rules={[{required: true, message: 'Please select platform'}]}
          label="Platform"
          name="platform">
          <Select style={{width: '100%'}} onSelect={onPlatformChange} disabled={!add}>
            {platforms && Object.keys(platforms).sort().map(platform => <Select.Option
              key={platform}
              value={platform}>{platform}</Select.Option>)
            }
          </Select>
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return (<Form.Item rules={[{required: true, message: 'Please select group'}]}
                               label="Group"
                               name="group">
              <Select style={{width: '100%'}} disabled={!add}>
                {platforms && form.getFieldValue('platform')
                  && platforms[form.getFieldValue('platform')]
                    .groupCurrency
                    .map(gc => gc.group)
                    .sort()
                    .map(group =>
                      <Select.Option key={group} value={group}>{group}</Select.Option>)
                }</Select>
            </Form.Item>)
          }}
        </Form.Item>
        <Form.Item shouldUpdate noStyle>
          {() => {
            return (<Form.Item rules={[{required: true, message: 'Please select group'}]}
                               label="Currency"
                               name="currency">
              <Select style={{width: '100%'}} disabled={!add}>
                {platforms && form.getFieldValue('platform')
                  && Array.from(new Set(platforms[form.getFieldValue('platform')]
                    .groupCurrency
                    .map(gc => gc.currency)))
                    .sort()
                    .map(currency =>
                      <Select.Option key={currency} value={currency}>{currency}</Select.Option>)
                }</Select>
            </Form.Item>)
          }}
        </Form.Item>
        <Form.Item label="Base Price" name="basePrice">
          <Input type={"number"}/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} label="Name (For engine)" name="name">
          <Input/>
        </Form.Item>
        <Form.Item rules={[{required: true}]} label="Title (For users)" name="title">
          <Input/>
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit"
                  icon={isLoading && <LoadingOutlined/>}> {add ? ' Add ' : ' Edit '} </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>;
};

export default AddEditCard;
