import React, {useCallback, useEffect, useState} from 'react';
import '../styles.scss';
import {shallowEqual, useDispatch, useSelector} from "react-redux";
import {getBalances} from "../../../Reducers/wallet";
import {ReloadOutlined, WalletOutlined} from "@ant-design/icons";
import useMediaQuery from '../../../Utils/useMediaQuery';
import MobMenu from './MobMenu';
import Sider from 'antd/lib/layout/Sider';
import { Menu, message } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';

function Balances() {

  const dispatch = useDispatch();

  const [externalWalletInfo, setExternalWalletInfo] = useState('Waiting for tron wallet info...');
  const [isRotating, setIsRotating] = useState(false);

  const fetchConversionRate = useCallback(async () => {
    const apiUrl = `https://apilist.tronscanapi.com/api/account/tokens?address=TCQ4dNQEHgEnkWGN9vzcGrDXbuY2Va1v6H`;
  
    try {

      const response = await fetch(apiUrl);
      const { data } = await response.json();

      const prepareBalanceString = data ? 
      data
      .filter((item) => item.tokenAbbr.toUpperCase() === "TRX" || item.tokenAbbr.toUpperCase() === "USDT")
      .map((item, index) => {
        return `${(+item.quantity).toFixed(0)} ${item.tokenAbbr.toUpperCase()}`
      }) : null

      const joinString = data ? prepareBalanceString?.join(' | ') : 'Waiting for tron wallet info...'
      return joinString
    } catch (error) {
      // setExternalWalletInfo('Waiting for tron wallet info')
      console.error('Error fetching conversion rate:', error);
    }
  }, [])

  //
  const balances = useSelector(state => state.wallet.balances.payload, shallowEqual);

  const handleClick = useCallback(() => {
    setIsRotating(true);
    dispatch(getBalances())
    fetchConversionRate()
      .then((res) => {
        setExternalWalletInfo(res);
        setTimeout(() => setIsRotating(false), 500)
      })
      .catch((error) => {
        setExternalWalletInfo('Waiting for tron wallet info')
        message.error('Error fetching conversion rate:', error)
      })
  }, [dispatch, fetchConversionRate]);

  useEffect(() => {
    dispatch(getBalances())
    fetchConversionRate()
      .then((res) => {
        setExternalWalletInfo(res)
      })
      .catch((error) => {
        setExternalWalletInfo('Waiting for tron wallet info')
        message.error('Error fetching conversion rate:', error)
      })
  }, [dispatch, fetchConversionRate])

  const media1250 = useMediaQuery('(max-width: 890px)')

  function transformObjToArray(obj) {
    const arr = [];
  
    for (const [key, value] of Object.entries(obj)) {
     
      const label = <span title={key} style={{cursor: 'default'}}>{value.length > 0 ? value : 'no data'}</span>;
      arr.push({ label, key });
    }
  
    return arr;
  }

  const transformedArr = balances && transformObjToArray(balances);
  console.log(transformedArr);

  return (
    <>
    {media1250 ? (
    <Sider width="63px" className="MobMenuBalances">
      
      <Menu
          mode="vertical"
          forceSubMenuRender={false}
          // defaultSelectedKeys={(path.length && [path[0].href]) || ''}
          // defaultOpenKeys={(path.length && [path[0].href]) || ''}
          // onOpenChange={() => setIcon(!icon)}
          onClick={handleClick}
          triggerSubMenuAction={"click"}
          style={{
            borderRight: 0,
          }}
        >
          <SubMenu
            key="menu"
            style={{fontSize: 15}}
            title={<WalletOutlined style={{fontSize: 20}}/>}
          >
            
                <Menu.Item>
                  {balances?.clientBalance}
                </Menu.Item>
                <Menu.Item>
                {externalWalletInfo}
              </Menu.Item>
            
          </SubMenu>
        </Menu>
        </Sider>
        ) : (
  <div className="Balance">
    <ReloadOutlined className={`reload ${isRotating ? 'reload-rotate' : ''}`} style={{marginRight: 10}} title="Fetch" onClick={handleClick}/>
    <a href="https://tronscan.org/#/address/TCQ4dNQEHgEnkWGN9vzcGrDXbuY2Va1v6H" rel="noreferrer noopener" target="_blank"
      title={"User balance | TRX Balance | USDT Balance"}>{balances && `${balances.clientBalance} | ${externalWalletInfo}`}</a>
  </div>
  ) }
  </>
  )
}

export default Balances;
