import React, { useCallback, useState } from 'react';
import {Button, Drawer, Form, Input, message } from 'antd';
import frc from 'front-end-common';
import {DeleteOutlined, LoadingOutlined} from "@ant-design/icons";
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { remove } from '../../../Reducers/codes';

const { useRequest, useBoolean } = frc.hooks;

function DeleteCode({ className, codeId, afterUpdate }) {

  const {value: isOpen, setFalse: close, setTrue: open} = useBoolean(false);
  const {error, isLoading} = useSelector(state => state.codes.remove, shallowEqual);
  const [password, setPassword] = useState('')
  const dispatch = useDispatch();

  const handleSuccess = useCallback((data) => {
    // console.log(`data: ${JSON.stringify(data)}`)
    // console.log(`data: ${data.confirm}`)
    const password = data.confirm
    dispatch(remove(codeId, password)).then(() => {
      message.success('Code has been removed, all transactions cleared!');
      close();
      afterUpdate();
    }).catch(error => {
      console.error(error);
      message.error('Failed to remove code.');
      close();
      afterUpdate();
    });
  }, [dispatch, codeId, close]);

  return (

    <>
    <Button className={className} type="link" size="small" danger icon={<DeleteOutlined />} onClick={open}>Remove</Button>
    <Drawer onClose={close}
            visible={isOpen}
            title="Remove code"
            placement="right"
            width={600}
    >
      <Form layout="vertical" onFinish={handleSuccess}>
        <Form.Item
          rules={[
            {required: true, message: 'Enter access password to confirm.'}
          ]}
          label="Enter access password to confirm."
          name="confirm"
        >
          <Input type="text" value={password} onChange={(e) => setPassword(e.target.value)} />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" icon={isLoading && <LoadingOutlined />}> Confirm </Button>
        </Form.Item>
      </Form>
    </Drawer>
  </>
  );
}

export default DeleteCode;
